<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 overflow-hidden"
      :open="open"
      @click="$emit('close')"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <form @submit.prevent="handleSubmit" class="w-screen max-w-md">
              <div
                class="
                  h-full
                  divide-y divide-gray-200
                  flex flex-col
                  bg-white
                  shadow-xl
                "
              >
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-auto">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-bold text-gray-900 w-52">
                        {{ action.update_type.action_title }}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button
                        type="button"
                          class="
                            bg-white
                            rounded-md
                            text-gray-400
                            hover:text-gray-500
                            focus:outline-none
                            focus:ring-2 focus:ring-gray-500
                          "
                          @click="$emit('close')"
                        >
                          <span class="sr-only">Close panel</span>
                          <XCircleIcon
                            class="h-6 w-6 text-gray-900"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                    <a
                      target="_blank"
                      :href="baseUrl + action.attachment.url"
                      v-if="
                        action.attachment?.id &&
                        action.update_type.uid !== 'payment'
                      "
                      class="
                        inline-flex
                        items-center
                        px-2.5
                        py-1.5
                        border
                        mt-6
                        border-gray-500
                        shadow-sm
                        text-xs
                        font-medium
                        rounded
                        text-gray-700
                        bg-white
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                      "
                    >
                      Download form as PDF
                      <DownloadIcon
                        class="ml-3 -mr-1 h-5 w-4"
                        aria-hidden="true"
                      />
                    </a>
                  </div>
                  <div class="mt-6 relative flex-1 px-4 sm:px-6">
                    <div>
                      <h3 class="font-medium text-gray-900">Information</h3>
                      <dl
                        class="
                          mt-2
                          border-t border-b border-gray-200
                          divide-y divide-gray-200
                        "
                      >
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Uploaded by</dt>
                          <dd class="text-gray-900">
                            {{ action.created_by.firstname }}
                            {{ action.created_by.lastname }}
                          </dd>
                        </div>
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Created</dt>
                          <dd class="text-gray-900">
                            {{
                              DateTime.fromISO(action.created_at).toFormat(
                                "MMMM dd, yyyy"
                              )
                            }}
                          </dd>
                        </div>
                        <div
                          v-if="
                            action.attachment?.id &&
                            action.update_type.uid !== 'payment'
                          "
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Document type</dt>
                          <dd class="text-gray-900">
                            {{ action.attachment.mime }} |
                            {{ action.attachment.size }} kb
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div
                      v-if="action.update_type.uid === 'payment'"
                      class="mt-4"
                    >
                      <h3 class="font-medium text-gray-900">Payment</h3>
                      <dl
                        class="
                          mt-2
                          border-t border-b border-gray-200
                          divide-y divide-gray-200
                        "
                      >
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Amount</dt>
                          <dd class="text-gray-900">
                            {{ action.amount }}
                            {{ getCurrencySymbol(action.currency) }}
                          </dd>
                        </div>
                        <div
                          v-if="action.payment_source.uid === 'stripe'"
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Payment Link</dt>
                          <dd class="text-gray-900">
                            <a
                              class="text-blue-600 hover:text-blue-900"
                              :href="action.payment_link"
                              >{{ action.payment_link }}</a
                            >
                          </dd>
                        </div>
                        <div
                          v-if="action.attachment?.id"
                          class="
                            py-3
                            flex
                            justify-between
                            items-center
                            text-sm
                            font-medium
                          "
                        >
                          <dt class="text-gray-500">Invoice</dt>
                          <dd class="text-gray-900">
                            <a
                              target="_blank"
                              :href="baseUrl + action.attachment.url"
                              v-if="
                                action.payment_source.uid === 'bank-transfer'
                              "
                              class="
                                inline-flex
                                items-center
                                px-2.5
                                py-1.5
                                border border-gray-500
                                shadow-sm
                                text-xs
                                font-medium
                                rounded
                                text-gray-700
                                bg-white
                                hover:bg-gray-50
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-blue-500
                              "
                            >
                              Preview invoice as PDF
                              <DownloadIcon
                                class="ml-3 -mr-1 h-5 w-4"
                                aria-hidden="true"
                              />
                            </a>
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div class="mt-4">
                      <label
                        for="file-upload"
                        class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px
                          sm:pb-2
                        "
                      >
                        Attachment
                      </label>
                      <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div
                          class="
                            max-w-lg
                            flex
                            justify-center
                            px-6
                            pt-5
                            pb-6
                            border-2 border-gray-300 border-dashed
                            rounded-md
                          "
                        >
                          <div class="space-y-1 text-center">
                            <svg
                              class="mx-auto"
                              width="28"
                              height="36"
                              viewBox="0 0 28 36"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.49935 19.8333H19.4994M13.9994 14.3333L13.9994 25.3333M23.166 34.5H4.83268C2.80764 34.5 1.16602 32.8584 1.16602 30.8333V5.16667C1.16602 3.14162 2.80764 1.5 4.83268 1.5H15.0733C15.5595 1.5 16.0258 1.69315 16.3697 2.03697L26.2957 11.963C26.6395 12.3068 26.8327 12.7732 26.8327 13.2594V30.8333C26.8327 32.8584 25.1911 34.5 23.166 34.5Z"
                                stroke="#9CA3AF"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>

                            <div class="flex text-sm text-gray-600">
                              <label
                                for="file-upload"
                                class="
                                  relative
                                  cursor-pointer
                                  bg-white
                                  rounded-md
                                  font-medium
                                  text-blue-600
                                  hover:text-blue-500
                                  focus-within:outline-none
                                  focus-within:ring-2
                                  focus-within:ring-offset-2
                                  focus-within:ring-blue-500
                                "
                              >
                                <span>Selec a file</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  accept="application/pdf"
                                  class="sr-only"
                                />
                                <input
                                  v-model="client_attachment"
                                  class="sr-only"
                                />
                              </label>
                              <p class="pl-1">or drag and drop</p>
                            </div>
                            <p class="text-xs text-gray-500">
                              PDF, PNG, JPEG up to 10MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <textarea
                        autofocus
                        v-model="client_note"
                        rows="3"
                        class="
                          shadow-sm
                          focus:ring-blue-500
                          focus:border-blue-500
                          block
                          w-full
                          sm:text-sm
                          border border-gray-300
                          rounded-md
                        "
                      ></textarea>
                      <span class="text-sm text-gray-500"
                        >Additional notes</span
                      >
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                    "
                    @click="$emit('close')"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-blue-600
                      hover:bg-blue-700
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                    "
                  >
                    Complete
                  </button>
                </div>
              </div>
            </form>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { XCircleIcon, DownloadIcon } from "@heroicons/vue/solid";
import { ref } from "vue";
import { getCurrencySymbol } from "../utils";
import { DateTime } from "luxon";
import { useStore } from "vuex";

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
export default {
  setup(props, context) {
    const client_note = ref("");
    const client_attachment = ref("");
    const store = useStore();
    const handleSubmit = () => {
      store.dispatch("action/completeAction", {
        id: props.action.id,
        data: {
          client_note: client_note.value,
        },
      });

      context.emit("close");
    };

    return {
      DateTime,
      client_note,
      client_attachment,
      getCurrencySymbol,
      handleSubmit,
      baseUrl: process.env.VUE_APP_API_URL,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Object,
      defualt: {},
    },
  },
  components: {
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    XCircleIcon,
    Dialog,
    DownloadIcon,
    TransitionRoot,
  },
};
</script>

<style>
</style> 