
<script>
import { ref, reactive } from "vue";
import { getEmbeddedSign } from "@/services/sign";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import ActionPanel from "./ActionPanel.vue";

import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import {
  PencilIcon,
  InformationCircleIcon,
  ClockIcon,
  DownloadIcon,
  DocumentDuplicateIcon,
} from "@heroicons/vue/solid";

import { getCurrencySymbol } from "../utils";

import HelloSign from "hellosign-embedded";
import { verification } from "../services/auth";

const tabs = [
  { name: "Active", href: "#", current: true },
  { name: "Completed", href: "#", current: false },
];

export default {
  props: {
    actions: {
      type: Array,
      required: true,
    },
  },
  components: {
    PencilIcon,
    InformationCircleIcon,
    ClockIcon,
    DocumentDuplicateIcon,
    DownloadIcon,
    ActionPanel,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  setup() {
    const open = ref(false);
    const verificationOpen = ref(false);
    const currentAction = ref({});
    const actionId = ref({});
    const webJourneyUrl = ref("");
    const state = reactive({ disabled: false });
    const store = useStore();
    const client = new HelloSign({
      clientId: "f078869176519d71899ea696680b7c06",
    });

    window.addEventListener(
      "message",
      async (event) => {
        if (event.origin !== window.location.origin) return;
        if (event.data === "credas:initialized") {
          await store.dispatch("action/pendingAction", { id: actionId.value });
          verificationOpen.value = false;
        }
      },
      false
    );

    const handleActionClick = async (template_id, id) => {
      try {
        client.off("sign");
        state.disabled = true;
        const {
          data: { sign_url },
        } = await getEmbeddedSign(template_id);
        client.open(sign_url, {
          skipDomainVerification: true,
          testMode: true,
        });
        client.on("cancel", () => {
          state.disabled = false;
        });

        client.on("sign", () => {
          state.disabled = false;
          client.off("sign");
          store.dispatch("action/completeAction", { id });
        });
      } catch (e) {
        state.disabled = false;
        throw new Error(e);
      }
    };

    const handleVerification = async (id) => {
      const { data } = await verification(id);
      actionId.value = id;
      webJourneyUrl.value = data.webJourneyUrl.url;
      verificationOpen.value = true;
    };

    const getDescription = (action) => {
      if (
        action.signature_type.uid === "digital-signature" &&
        action.update_type.uid !== "verification"
      ) {
        return action.template_name;
      }
      if (action.update_type.uid === "payment") {
        return `${getCurrencySymbol(action.currency)}${action.amount}, ${
          action.payment_source.name
        }`;
      }
      if (action.update_type.uid === "verification") {
        return action.update_type.title;
      }
    };

    return {
      tabs,
      open,
      DateTime,
      handleActionClick,
      getDescription,
      state,
      currentAction,
      handleVerification,
      webJourneyUrl,
      verificationOpen,
    };
  },
};
</script>

<template>
  <ActionPanel :action="currentAction" @close="open = false" :open="open" />
  <TransitionRoot as="template" :show="verificationOpen">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="verificationOpen = false"
      :open="verificationOpen"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block
          sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8
              sm:align-middle
              max-w-4xl
              w-full
              h-screen-iframe
            "
          >
            <div
              v-if="webJourneyUrl"
              class="relative h-screen-iframe"
              style="padding-top: 56.25%"
            >
              <iframe
                allow="camera *;microphone *"
                :src="webJourneyUrl"
                class="absolute inset-0 w-full h-full"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul class="divide-y divide-gray-200">
      <template v-for="action in actions" :key="action.id">
        <li
          :class="[
            action.update_status.uid === 'waiting-for-digital-signature' ||
              (action.update_status.uid === 'waiting-for-verification' &&
                'cursor-pointer'),
            state.disabled && 'cursor-wait',
          ]"
          @click="
            action.update_status.uid === 'waiting-for-digital-signature' &&
            !state.disabled
              ? handleActionClick(action.template_id, action.id)
              : action.update_status.uid === 'waiting-for-verification' &&
                handleVerification(action.id)
          "
        >
          <div class="block hover:bg-gray-50">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div
                  class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
                >
                  <div>
                    <p class="text-sm font-medium text-blue-900 truncate">
                      {{ action.update_type.action_title }}
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <InformationCircleIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span class="truncate">
                        {{ getDescription(action) }}</span
                      >
                    </p>
                  </div>
                  <div class="flex justify-between items-center">
                    <div>
                      <p class="text-sm text-gray-900">
                        <time :datetime="action.created_at">{{
                          DateTime.fromISO(action.created_at).toFormat(
                            "MMMM dd, yyyy"
                          )
                        }}</time>
                        {{ " " }}
                      </p>
                      <p
                        v-if="action.update_status.uid === 'completed'"
                        class="mt-2 flex items-center text-sm text-gray-500"
                      >
                        <DocumentDuplicateIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                        {{ action.update_status.name }}
                      </p>
                      <p
                        v-else
                        class="mt-2 flex items-center text-sm text-gray-500"
                      >
                     
                        <ClockIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                        {{ action.update_status.name }}
                      </p>
                    </div>
                    <div class="w-auto">
                      <span
                        v-if="action.update_status.uid === 'completed'"
                        aria-pressed="mixed"
                        tabindex="0"
                        role="button"
                      >
                        <DownloadIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                      <span
                        @click="
                          open = true;
                          currentAction = action;
                        "
                        @keyup.enter="
                          open = true;
                          currentAction = action;
                        "
                        @keyup.space="
                          open = true;
                          currentAction = action;
                        "
                        v-else-if="
                          action.update_status.uid !==
                            'waiting-for-digital-signature' &&
                          action.update_status.uid !==
                            'waiting-for-verification' &&
                          action.update_status.uid !==
                            'pending-for-verification'
                        "
                        aria-pressed="mixed"
                        tabindex="0"
                        role="button"
                      >
                        <PencilIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </template>

      <li class="px-4 py-4 sm:px-6" v-if="actions.length === 0">
        There is no action
      </li>
    </ul>
  </div>
</template>
